import * as styles from "./Footer.module.scss";

import { Link, graphql, useStaticQuery } from "gatsby";

import React from "react";

export default function Footer() {
  const author = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          author
        }
      }
    }
  `);

  return (
    <footer className={styles.footer}>
      <div className={styles.list}>
        <p>
          <Link to="/about">About</Link>
        </p>
        <p>
          <Link to="/blog">Blog</Link>
        </p>
        <p>
          <Link to="/pricing">Pricing</Link>
        </p>
        <p>
          <Link to="/privacy">Privacy</Link>
        </p>
      </div>
      <div>
        <p>
          {" "}
          © {new Date().getFullYear()} {author.site.siteMetadata.author}
        </p>
      </div>
    </footer>
  );
}
