import * as styles from "./Contact.module.scss";

import { Link } from "gatsby";
import React from "react";
import clsx from "clsx";

export default function Contact() {
  return (
    <div id="contact" className={clsx(styles.contact, styles.pageContent)}>
      <div className={styles.contactText}>
        <p>
          Lithic Tech was founded in Portland, Oregon by experienced software engineers
          with a track record of high-quality software, happy customers, and successful
          businesses. We believe this success is a result of our unique processes,
          patterns, and convictions.
        </p>
        <p>
          We are now offering that expertise to the wider community to build better
          software and software teams. You can{" "}
          <Link to="/about">learn more about us</Link>, and we&rsquo;d love if you got
          in touch.
        </p>
      </div>

      <div className={styles.contactFormContainer}>
        <p>
          Email us at <a href="mailto:hello@lithic.tech">hello@lithic.tech</a> or use
          this form:
        </p>

        <form
          className={styles.contactForm}
          name="contact"
          method="POST"
          action={"/contact-success/"}
          data-netlify="true"
          netlify-honeypot="totally-real"
        >
          <input type="hidden" name="form-name" value="contact" />
          <div className={styles.notViz}>
            <input type="text" name="totally-real" />
          </div>
          <input
            className={styles.text}
            id="name"
            name="name"
            type="text"
            aria-label="Name"
            placeholder="Name"
            required
          />
          <input
            className={styles.text}
            id="email"
            name="email"
            type="email"
            aria-label="Email"
            placeholder="Email Address"
            required
          />
          <textarea
            className={styles.text}
            id="message"
            name="message"
            aria-label="Message"
            placeholder="Message"
            required
          />
          <div className={styles.btnContainer}>
            <input
              className={styles.btn}
              id="input-submit"
              type="submit"
              aria-label="Submit"
              value="Send Message"
            />
            <input
              className={styles.btn}
              id="input-submit"
              type="reset"
              aria-label="Reset"
              value="Clear"
            />
          </div>
        </form>
      </div>
    </div>
  );
}
