import * as styles from "./Header.module.scss";

import { Link } from "gatsby";
import React from "react";
import clsx from "clsx";

export default function Header({ altNav }) {
  return (
    <header className={clsx(styles.header, altNav && styles.altHeader)}>
      <nav>
        <ul className={styles.navList}>
          <li>
            <Link
              className={styles.navItem}
              activeClassName={styles.activeNavItem}
              to="/"
            >
              Home
            </Link>
          </li>
          <li>
            <Link
              className={styles.navItem}
              activeClassName={styles.activeNavItem}
              to="/blog"
              partiallyActive={true}
            >
              Blog
            </Link>
          </li>
          <li>
            <Link
              className={styles.navItem}
              activeClassName={styles.activeNavItem}
              to="/about"
              partiallyActive={true}
            >
              About
            </Link>
          </li>
          <li>
            <Link className={styles.navItem} to="#contact">
              Contact
            </Link>
          </li>
        </ul>
      </nav>
    </header>
  );
}
