import * as styles from "./Layout.module.scss";

import Contact from "./Contact";
import Footer from "./Footer";
import FullWidthImage from "./FullWidthImage";
import Header from "./Header";
import React from "react";

export default function Layout({ children, headerProps, hideContact }) {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <Header {...headerProps} />
        {children}
      </div>
      <FullWidthImage image="footer" short className={styles.footerImage} />
      {!hideContact && <Contact />}
      <Footer />
    </div>
  );
}
