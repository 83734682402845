import * as styles from "./FullWidthImage.module.scss";

import { graphql, useStaticQuery } from "gatsby";

import Img from "gatsby-image";
import React from "react";
import clsx from "clsx";

const breakPointsAndImages = [
  { bp: "Sm", size: 900 },
  { bp: "Md", size: 1200 },
  { bp: "Lg", size: 1800 },
  { bp: "Xl", size: 2400 },
  { bp: "Xxl" },
];
export default function FullWidthImage({ image, className, short }) {
  const data = useStaticQuery(allImagesQuery);
  const sources = breakPointsAndImages.map(({ bp, size }) => {
    const o = {
      ...data[image + bp].childImageSharp.fluid,
    };
    if (size) {
      o.media = `(max-width: ${size}px`;
    }
    return o;
  });
  return (
    <Img
      fluid={sources}
      alt="Big Background"
      className={clsx(styles.image, className, short && styles.short)}
    />
  );
}

/**
 * IMPORTANT
 *
 * As of Gatsby >= 4, we started to hit OOM issues
 * when processing images, as per https://github.com/gatsbyjs/gatsby/issues/33738
 * The only reliable way around this is to preprocess the images
 * with `sharp` directly, so they match the correct size
 * and thus need minimal further processing
 * (we still use gatsby-image for the fluid image choices).
 *
 * The queries here must match the output files in preoptimize-images.js.
 * That file outputs high-quality jpegs,
 * which are then read in (and possibly re-optimized? I can't tell)
 * via gatsby-image at build time. But the memory usage
 * is a lot lower than doing the resizing at build time.
 *
 * All the input parameters in preprocess-images.js
 * must match the parameters that are here.
 *
 * It seems insane that we can't just do this in Gatsby
 * (since the pre-optimize step does not take much memory),
 * but here we are. If we cared enough,
 * I think we could do this as a build step during the build
 * and get the images out of source control, but we don't care enough.
 */
// We need all blocks to be the same line structure for easy editing.
// prettier-ignore
const allImagesQuery = graphql`
  query Images {
    landingXxl: file(relativePath: { eq: "saxon-switzerland-539418_1920-3500x400.jpg" }) {
      id
      childImageSharp {
        fluid(quality: 70, maxHeight: 400, maxWidth: 3500, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    landingXl: file(relativePath: { eq: "saxon-switzerland-539418_1920-1800x400.jpg" }) {
      id
      childImageSharp {
        fluid(quality: 70, maxHeight: 400, maxWidth: 1800, cropFocus: ATTENTION) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    landingLg: file(relativePath: { eq: "saxon-switzerland-539418_1920-1200x400.jpg" }) {
      id
      childImageSharp {
        fluid(quality: 70, maxHeight: 400, maxWidth: 1200, cropFocus: ATTENTION) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    landingMd: file(relativePath: { eq: "saxon-switzerland-539418_1920-900x400.jpg" }) {
      id
      childImageSharp {
        fluid(quality: 70, maxHeight: 400, maxWidth: 900, cropFocus: ATTENTION) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    landingSm: file(relativePath: { eq: "saxon-switzerland-539418_1920-600x400.jpg" }) {
      id
      childImageSharp {
        fluid(quality: 70, maxHeight: 400, maxWidth: 600, cropFocus: ATTENTION) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    blogXxl: file(relativePath: { eq: "dawn-armfield-R9S19dOFlcs-unsplash-3500x400.jpg" }) {
      id
      childImageSharp {
        fluid(quality: 70, maxHeight: 400, maxWidth: 3500, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    blogXl: file(relativePath: { eq: "dawn-armfield-R9S19dOFlcs-unsplash-1800x400.jpg" }) {
      id
      childImageSharp {
        fluid(quality: 70, maxHeight: 400, maxWidth: 1800, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    blogLg: file(relativePath: { eq: "dawn-armfield-R9S19dOFlcs-unsplash-1200x400.jpg" }) {
      id
      childImageSharp {
        fluid(quality: 70, maxHeight: 400, maxWidth: 1200, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    blogMd: file(relativePath: { eq: "dawn-armfield-R9S19dOFlcs-unsplash-900x400.jpg" }) {
      id
      childImageSharp {
        fluid(quality: 70, maxHeight: 400, maxWidth: 900, cropFocus: ATTENTION) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    blogSm: file(relativePath: { eq: "dawn-armfield-R9S19dOFlcs-unsplash-600x400.jpg" }) {
      id
      childImageSharp {
        fluid(quality: 70, maxHeight: 400, maxWidth: 600, cropFocus: ATTENTION) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    aboutXxl: file(relativePath: { eq: "connor-dugan-D3fDbLlerpY-unsplash-3500x400.jpg" }) {
      id
      childImageSharp {
        fluid(quality: 70, maxHeight: 400, maxWidth: 3500, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    aboutXl: file(relativePath: { eq: "connor-dugan-D3fDbLlerpY-unsplash-1800x400.jpg" }) {
      id
      childImageSharp {
        fluid(quality: 70, maxHeight: 400, maxWidth: 1800, cropFocus: ATTENTION) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    aboutLg: file(relativePath: { eq: "connor-dugan-D3fDbLlerpY-unsplash-1200x400.jpg" }) {
      id
      childImageSharp {
        fluid(quality: 70, maxHeight: 400, maxWidth: 1200, cropFocus: ATTENTION) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    aboutMd: file(relativePath: { eq: "connor-dugan-D3fDbLlerpY-unsplash-900x400.jpg" }) {
      id
      childImageSharp {
        fluid(quality: 70, maxHeight: 400, maxWidth: 900, cropFocus: ATTENTION) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    aboutSm: file(relativePath: { eq: "connor-dugan-D3fDbLlerpY-unsplash-600x400.jpg" }) {
      id
      childImageSharp {
        fluid(quality: 70, maxHeight: 400, maxWidth: 600, cropFocus: ATTENTION) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    pricingXxl: file(relativePath: { eq: "ben-klea-zXODFyN1eII-unsplash-3500x400.jpg" }) {
      id
      childImageSharp {
        fluid(quality: 70, maxHeight: 400, maxWidth: 3500, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    pricingXl: file(relativePath: { eq: "ben-klea-zXODFyN1eII-unsplash-1800x400.jpg" }) {
      id
      childImageSharp {
        fluid(quality: 70, maxHeight: 400, maxWidth: 1800, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    pricingLg: file(relativePath: { eq: "ben-klea-zXODFyN1eII-unsplash-1200x400.jpg" }) {
      id
      childImageSharp {
        fluid(quality: 70, maxHeight: 400, maxWidth: 1200, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    pricingMd: file(relativePath: { eq: "ben-klea-zXODFyN1eII-unsplash-900x400.jpg" }) {
      id
      childImageSharp {
        fluid(quality: 70, maxHeight: 400, maxWidth: 900, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    pricingSm: file(relativePath: { eq: "ben-klea-zXODFyN1eII-unsplash-600x400.jpg" }) {
      id
      childImageSharp {
        fluid(quality: 70, maxHeight: 400, maxWidth: 600, cropFocus: ATTENTION) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    footerXxl: file(relativePath: { eq: "dave-herring-FhnhWwTJk0s-unsplash-3500x400.jpg" }) {
      id
      childImageSharp {
        fluid(quality: 90, maxHeight: 400, maxWidth: 3500, cropFocus: ENTROPY) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    footerXl: file(relativePath: { eq: "dave-herring-FhnhWwTJk0s-unsplash-1800x400.jpg" }) {
      id
      childImageSharp {
        fluid(quality: 90, maxHeight: 400, maxWidth: 1800, cropFocus: NORTH) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    footerLg: file(relativePath: { eq: "dave-herring-FhnhWwTJk0s-unsplash-1200x400.jpg" }) {
      id
      childImageSharp {
        fluid(quality: 90, maxHeight: 400, maxWidth: 1200, cropFocus: NORTH) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    footerMd: file(relativePath: { eq: "dave-herring-FhnhWwTJk0s-unsplash-900x400.jpg" }) {
      id
      childImageSharp {
        fluid(quality: 90, maxHeight: 400, maxWidth: 900, cropFocus: NORTH) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    footerSm: file(relativePath: { eq: "dave-herring-FhnhWwTJk0s-unsplash-600x400.jpg" }) {
      id
      childImageSharp {
        fluid(quality: 90, maxHeight: 400, maxWidth: 600, cropFocus: NORTH) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
