// extracted by mini-css-extract-plugin
export var btn = "Contact-module--btn--q320H";
export var btnContainer = "Contact-module--btn-container--+i22c";
export var contact = "Contact-module--contact--RywUG";
export var contactForm = "Contact-module--contact-form--SvWsM";
export var contactFormContainer = "Contact-module--contact-form-container--sazoI";
export var contactText = "Contact-module--contact-text--Yz7bh";
export var intro = "Contact-module--intro--zRMju";
export var notViz = "Contact-module--not-viz--k1E6v";
export var pageContent = "Contact-module--page-content--AzZ20";
export var sectionSpacingB = "Contact-module--section-spacing-b--kgvf1";
export var sectionSpacingT = "Contact-module--section-spacing-t--9PegM";
export var text = "Contact-module--text--jiaIf";